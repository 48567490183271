import React, { useEffect, useRef } from 'react'
import { motion, useAnimation, useInView } from "framer-motion"
import { getMaskStaticPathWithAspectRatio } from "../../utils/staticPathsForMaskImages"
import { svgCirclePathDRelativeToCenter, svgPathsDRelativeToCenterOfCirclesAround, svgPathsRelativeToCenterOfOuterCircleAndSmallCirclesAround, SvgWithPathsOfOuterCircleAndSmallCirclesAround } from "../../utils/calculateSvgPath"

const mapImgStyle = {
    fullImgWidthInFullPageWidthAndFullImgHeightDeformed: {
        objectFit: 'fill',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0'
    },
    fullImgWidthInFullPageWidthAndProperAmountOfImgForHeight: {
        objectFit: 'cover',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0'
    },
    fullAvailableWidth: {
        // objectFit: 'cover',
        // width: '100%',
    }
}

const ImageWithClipPathAnimations = ({
    clipPathId = "clipPathId",
    srcImg,
    imgLoading = "lazy",
    imgMaskName = "mask3",
    imgNamedStyle = "fullAvailableWidth", // "fullImgWidthInFullPageWidthAndProperAmountOfImgForHeight", // "fullImgWidthInFullPageWidthAndFullImgHeightDeformed", // "fullAvailableWidth",
    clipPathFrom,
    // clipPathMid,
    clipPathTo = [],
    isAnimation = true,
    isFitMaskHeightToImg = true,
    isFitMaskWidthToImg = true,
    widthWrap = "auto",
    heightWrap = "auto",
    styleWrap = { minHeight: "100px" },
    imgHeight = "100%",
    isUsedMask = true,
    maskSizeHeightStartDirect = "100%",
    maskSizeWidthStartDirect = "100%",
    maskSizeHeightEndDirect = "200%",
    maskSizeWidthEndDirect = "200%",
    isMaskAnimation = true

}) => {
    const ctrlClipPath = useAnimation()
    const ctrlImg = useAnimation()

    const refWrap = useRef(null)
    const inView = useInView(refWrap)

    // console.log("clipPathId", clipPathId)
    // console.log("clipPathFrom", clipPathFrom)
    // console.log("clipPathTo", clipPathTo)
    // const isMaskAnimation = (isFitMaskHeightToImg && isFitMaskWidthToImg) ? false : isAnimation
    // const isMaskAnimation = isMaskAnimationDirect ? isMaskAnimationDirect : isAnimation
    const maskSizeWidthEnd = maskSizeWidthEndDirect ? maskSizeWidthEndDirect : isFitMaskWidthToImg ? "auto" : "100%"
    // console.log("maskSizeWidthEnd", maskSizeWidthEnd)
    const maskSizeHeightEnd = maskSizeHeightEndDirect ? maskSizeHeightEndDirect : isFitMaskHeightToImg ? "auto" : "100%"
    // console.log("maskSizeHeightEnd", maskSizeHeightEnd)
    const maskSizeHeightStart = maskSizeHeightStartDirect ? maskSizeHeightStartDirect : isFitMaskHeightToImg ? "auto" : "33%"
    const maskSizeWidthStart = maskSizeWidthStartDirect ? maskSizeWidthStartDirect : isFitMaskWidthToImg ? "auto" : "33%"
    useEffect(() => {
        let isMount = true
        if (!inView) {
            ctrlClipPath.stop()
            ctrlImg.stop()
            return
        }
        const seq1 = async () => {
            isMount && inView && await ctrlClipPath.start(() => ({
                // opacity: 0.5,
                d: clipPathTo[ 1 ],
                // pathLength: 1, pathOffset: 0,
                transition: {
                    duration: 1,
                    // delay: (animTimeMs / 3) * i / 1000
                }
            }))
            isMount && inView && await ctrlClipPath.start(() => ({
                // opacity: 0.5,
                d: clipPathFrom,
                // pathLength: 1, pathOffset: 0,
                transition: {
                    duration: 1,
                    // delay: (animTimeMs / 3) * i / 1000
                }
            }))
            isMount && inView && isUsedMask && isMaskAnimation && await ctrlImg.start(() => ({

                // maskPosition: "50%",
                // maskSize: `0% ${maskSizeHeightStart}`, //"100%",
                maskSize: `0% 100%`, //"100%",
                // maskSize: "100% 100%",
                transition: {
                    // type: "spring", stiffness: 100,
                    duration: 1,
                    ease: "easeInOut",
                    // delay: 2

                }
            }))
            isMount && inView && await ctrlClipPath.start(() => ({
                d: clipPathTo[ 1 ],
                transition: {
                    duration: 1,
                    ease: "easeInOut"
                }
            }));
            isMount && inView && isUsedMask && isMaskAnimation && await ctrlImg.start(() => ({

                // maskPosition: "50%",
                maskSize: `${maskSizeWidthStart} ${maskSizeHeightStart}`, //"100%",
                // maskSize: "100% 100%",
                transition: {
                    // type: "spring", stiffness: 100,
                    duration: 1,
                    ease: "easeInOut",
                    // delay: 2

                }
            }))

            for (const path of clipPathTo.slice(2)) {
                isMount && inView && await ctrlClipPath.start(() => ({
                    d: path,
                    transition: {
                        duration: 1,
                        ease: "easeInOut"
                    }
                }));
            }

            isMount && inView && isUsedMask && isMaskAnimation && ctrlImg.start(() => ({

                // maskPosition: "50%",
                maskSize: `${maskSizeWidthEnd} ${maskSizeHeightEnd}`, //"100%",
                // maskSize: "25% 25%", //"100%",
                // maskSize: "100% 100%",
                transition: {
                    // type: "spring", stiffness: 100,
                    duration: 1,
                    ease: "easeInOut",
                    // delay: 2

                }
            }))
        }
        isMount && inView && seq1()
        return () => {
            isMount = false
            ctrlClipPath.stop()
            ctrlImg.stop()
        }
    }, [ inView, maskSizeHeightStart, maskSizeWidthStart, ctrlImg, clipPathFrom, isUsedMask, isMaskAnimation, maskSizeHeightEnd, maskSizeWidthEnd, ctrlClipPath, clipPathTo ])
    const staticMaskImgPath = getMaskStaticPathWithAspectRatio(imgMaskName).path

    const imgStyle = mapImgStyle[ imgNamedStyle ]

    // const { dOuterCircle, dsCirclesAround } = svgPathsRelativeToCenterOfOuterCircleAndSmallCirclesAround()
    // const dOuterCircle = svgCirclePathDRelativeToCenter()
    // const dsCirclesAround = svgPathsDRelativeToCenterOfCirclesAround()
    // console.log("dOuterCircle", dOuterCircle)
    // console.log("dsCirclesAround", dsCirclesAround)
    return (
        <div
            style={{
                position: "relative", marginLeft: 'auto', marginRight: 'auto', width: widthWrap,
                height: heightWrap,
                ...styleWrap
            }}
            ref={refWrap}
        >
            <svg width="100%" height="100%" viewBox="0 0 100 100" style={{ position: 'absolute' }}>

                <defs>
                    <clipPath
                        id={clipPathId}
                        clipPathUnits="objectBoundingBox"
                    >
                        <motion.path
                            d=""
                            // d={clipPathFrom}
                            // d={dOuterCircle}
                            // initial={{ pathLength: 0, pathOffset: 1 }}
                            animate={ctrlClipPath}
                        // fill="#FFFFFF"
                        // fill="#000000"
                        // fill="black"
                        // fillOpacity={0.5}
                        // stroke="#000000"
                        // strokeMiterlimit="10"
                        // style={{
                        //     pathLength: 0, 
                        //     pathOffset: 1,
                        // }}
                        />

                    </clipPath>
                </defs>

            </svg>
            <motion.img
                src={srcImg}
                // width="100%"
                // height="100%"
                animate={ctrlImg}
                style={{
                    // clipPath: 'url(#clipPath)',
                    clipPath: `url(#${clipPathId})`,
                    // position: 'absolute',
                    // objectFit: 'cover',
                    width: '100%',
                    // maskImage: 'radial-gradient(circle at 50% 50%, black 0%, black 25%, transparent 75%, transparent 100%)', // Apply the mask here
                    // // WebkitMaskImage: 'radial-gradient(circle at 50% 50%, transparent 0%, transparent 25%, black 75%, black 100%)', // For Safari
                    WebkitMaskImage: isUsedMask ? `url(${staticMaskImgPath})` : 'none',
                    maskImage: isUsedMask ? `url(${staticMaskImgPath})` : 'none',
                    maskSize: !isUsedMask ? "none" : isMaskAnimation ? `${maskSizeWidthStart} ${maskSizeHeightStart}` : `${maskSizeWidthEnd} ${maskSizeHeightEnd}`,
                    maskPosition: isUsedMask ? '50%' : '0% 0%',
                    objectPosition: '50% 50%',

                    ...imgStyle,
                    height: imgHeight,
                }}
                loading={imgLoading}
            />
        </div>
    )
}
export default ImageWithClipPathAnimations



{/* {svgWithCircles} */ }
{/* <SvgWithPathsOfOuterCircleAndSmallCirclesAround /> */ }
{/* <svg width="100" height="100" viewBox="0 0 100 100"> */ }
{/* <div xmlns="http://www.w3.org/2000/svg">
                <clipPath id="clipPath" clipPathUnits="objectBoundingBox">
                    <motion.path
                        d={pathCirclesAround}
                        animate={ctrlImg}
                        fill="#FFFFFF" stroke="#000000" strokeMiterlimit="10"
                    />
                </clipPath>
            </div> */}
{/* <svg width="100" height="100" viewBox="0 0 100 100"> */ }
// clipPath: { dOuterCircle },
// clipPath: `path(${clipPathFrom})`,
// clipPath: `url(${pathCirclesAround})`,
// clipPath: `url(${dOuterCircle})`,

// clipPath: `d(pathCirclesAround)`,


{/* <motion.path
d={clipPathFrom}
animate={ctrlImg}
fill="#FFFFFF"
// fill="000000"
stroke="#000000" strokeMiterlimit="10"
/>
<image
href={srcImg}
width="100%"
height="100%"
clipPath="url(#clipPath)"
/> */}


// await ctrlImg.start(() => ({
//     // d: clipPathFrom,
//     // pathLength: 0, pathOffset: 1,
//     transition: {
//         duration: 2,
//         // delay: (animTimeMs / 3) * i / 1000
//     }
// }))
// await ctrlImg.start(() => ({
//     // d: clipPathFrom,
//     pathLength: 1, pathOffset: 0,
//     transition: {
//         duration: 2,
//         // delay: (animTimeMs / 3) * i / 1000
//     }
// }))
